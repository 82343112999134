import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El seu peculiar hàbitat fa que encara que tengui la cutícula humida, no és per gaire temps, a causa de la brisa marina. El capell és inicialment convex per passar a aplanat amb una depressió central, de color ocre, més fosc al centre. Aquest mesura fins uns 2,5 cm de diàmetre i té el marge acanalat. Té poques làmines, molt separades i blanquinoses. El peu és llarg amb la meitat inferior més fosca i enfonsada dins l’arena. Les espores són blanques en massa, més o manco el·líptiques, de 8-10 x 13,5-15 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      